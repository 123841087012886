import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import CallButton from "@/components/CallButton.vue";

const defaultProps = {
  internationalPhone: "+34612345678",
};

const component = (props = defaultProps) => {
  return myRentalsShallowMount(CallButton, {
    props,
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("CallButton", () => {
  testMyRentalsShallowMount(CallButton, {
    props: defaultProps,
  });

  describe("Computed", () => {
    describe("handleButtonClick", () => {
      it("Should make a call", () => {
        // given
        Object.defineProperty(window, "location", { value: { href: null } });
        const button = component();
        // when
        button.vm.handleButtonClick();
        // then
        expect(location.href).toContain(
          `tel:${defaultProps.internationalPhone}`
        );
      });
    });
  });
});
